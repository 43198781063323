import * as React from 'react'

import { navigate } from 'gatsby'
import { isFrontend } from '../utils/checkSide'

export interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
  allStrapiArticle: {
    nodes: Article[]
  }
}

const IndexPage = () => {
  if (isFrontend) navigate('/home')

  return <></>
}

export default IndexPage
